@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Work+Sans:wght@100..900&display=swap');

:root {
    --inplay-sectin-header-color: #2947A2;
    --font-family: "Inter", sans-serif;
    --inplay-section-header-logo-text-color: #ffffff;
    --inplay-header-logo-bg-color: #D6DF3C;
    --white-color: #ffffff;
    --total-open-bets-box-color: #D6DF3C;
    --sports-card-sports-name: #333333;
    --comman-sports-table-border-color: #D9D9D9;
    --sport-score-details-color: #2947A2;
    --match-team-name-color: #333333;
    --black-color: #333333;
    --upcoming-events-section-color: #2947A2;

}


.inplay-sports-page-section {
    width: 100% !important;

}
.desktop-inplay-sports-page-section{
    width: 97%;
    margin: auto;
}


.inplay-and-openbets-content {
    width: 100% !important;
    height: 55px;
    background-color:  #f00000 !important;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;

}


.inplay-image-and-heading {
    margin-left: 8px;
    display: flex;
    align-items: center;
}

.inplay-heading {
    font-size: 1.1rem;
    font-weight: 500;
    margin-left: 7px;
    color: #2947A2;
}

.inplay-image {
    width: 35px;
    height: 26px;
}

.inplay-image img {
    width: 100%;
    height: 100%;
}

.openbets-content {
    display: flex;
    align-items: center;
    margin-right: 8px;
}

.open-bets-link {
    margin-right: 8px;
}

.open-bets-link .link {
    font-family: var(--font-family);
    font-size: 1rem;
    color: #000000;
}

.total-open-bets-box {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2947A2;
    color: var(--white-color);
    clip-path: circle();
}

.inplay-sports-sections {
    width: 100%;
    margin-top: 20px;
}

.cricket-inplay-section {
    margin-top: 14px;
}

.football-inplay-section {
    margin-top: 14px;
}

.tennis-inplay-section {
    margin-top: 14px;
}


/* end of top navigation bar styling -------------------------- */
.upcoming-events-section {
    width: 100%;
}

.upcoming-events-header {
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    background-color: #ECECEC !important;
    padding: 5px 12px 5px 12px;
}

.upcoming-img {
    width: 36px;
    height: 36px;
}

.upcoming-img img {
    width: 100%;
    height: 100%;
}

.upcoming-heading {
    font-size: 17px;
    color: var(--upcoming-events-section-color);
    font-weight: 500;
    margin-left: 12px;
}



.upcoming-sports-cards-contaienr {
    margin-top: 10px;
}



































.inplay-section-header {
    width: 100%;
    height: 65px;
    background-color: var(--inplay-sectin-header-color);
}

.inply-sectin-header-content {
    width: 90%;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.inplay-section-header-logo {
    display: flex;
    align-items: center;
}

.inplay-logo {
    width: 45px;
    height: 45px;
    background-color: var(--inplay-header-logo-bg-color);
    margin-right: 10px;
    clip-path: circle();
    display: flex;
    align-items: center;
    justify-content: center;
}

.inplay-icon {
    color: var(--inplay-sectin-header-color);
    font-size: 34px;
}

.inplay-section-header-logo .inplay-text {
    font-family: var(--font-family);
    font-size: 1.4rem;
    color: var(--inplay-section-header-logo-text-color);
    font-weight: 600;
}


.inplay-header-open-bets-part {
    display: flex;
    align-items: center;
}

.open-bets-text {
    font-family: var(--font-family);
    color: var(--white-color);
    font-size: 0.9rem;
    text-transform: capitalize;
}

.total-open-bets-value {
    padding: 5px;
    clip-path: circle();
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--total-open-bets-box-color);
    color: var(--white-color);
    margin-left: 4px;

}


/* All Sports section ------------------------ */
.all-sports-section {
    margin-top: 20px;
}


.all-sports-section-container {
    width: 100%;
}



.sport-card {
    width: 100%;
    margin: auto;
    margin-top: 30px;
}

.spor-card-header {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

.sport-header-content {
    width: 55%;
    display: flex;
    align-items: center;
    padding-left: 12px;
    box-sizing: border-box;
}

.sport-image {
    width: 33px;
    height: 33px;
    margin-right: 10px;
}

.sport-image img {
    width: 100%;
    height: 100%;
}

.sport-name {
    font-family: var(--font-family);
    font-size: 1.3rem;
    color: var(--sports-card-sports-name);
    font-weight: 650;
}

.players-number-marker {
    width: 45%;
    display: flex;
    justify-content: space-between;
}

.first-marker {
    width: 32%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    color: var(--black-color);
}

.vs-icon {
    width: 32%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    color: var(--black-color);
}

.second-marker {
    width: 32%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    color: var(--black-color);
}

.sport-table {
    width: 100%;
    margin-top: 20px;


}

.sports-table-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: 1px solid var(--comman-sports-table-border-color);
    border-left: 0px;
}

.match-score-and-team-details-section {
    width: 53%;
    display: flex;
    justify-content: space-between;
}

.match-score-details {
    width: 25%;
    height: 55px;
    border: 1px solid var(--comman-sports-table-border-color);
    border-left: 0px;
    padding: 5px;
    box-sizing: border-box;
    text-align: center;
}

.total-over {
    font-family: var(--font-family);
    font-size: 0.6rem;
    color: var(--sport-score-details-color);
}

.target {
    margin-top: 3px;
    font-family: var(--font-family);
    font-size: 0.6rem;
    color: var(--sport-score-details-color);
}

.current-team-score {
    margin-top: 3px;
    font-family: var(--font-family);
    font-size: 0.6rem;
    color: var(--sport-score-details-color);
}

.match-teams {
    width: 70%;
    height: 55px;
    padding-left: 10px;
    padding-top: 12px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    border-right: 1px solid var(--comman-sports-table-border-color);
    padding-right: 5px;
}

.teams .team1 {
    font-size: 0.6rem;
    font-weight: 650;
    font-family: var(--font-family);
    color: var(--match-team-name-color);
}

.teams .team2 {
    font-size: 0.6rem;
    font-weight: 650;
    font-family: var(--font-family);
    color: var(--match-team-name-color);
    position: relative;
    margin-top: 4px;
}

.teams .team2::before {
    content: "";
    width: 6px;
    height: 6px;
    position: absolute;
    top: 50%;
    left: -10px;
    transform: translateY(-50%);
    background-color: var(--inplay-sectin-header-color);
    border-radius: 50%;
}

.live-icon-image .live-icon {
    color: #2947A2;
}



/* Bet boxes section --------------------------- */
.bets-boxes {
    width: 47%;
    display: flex;
    justify-content: space-between;
}

.first-player-bet-details {
    width: 32%;
    border-right: 1px solid var(--comman-sports-table-border-color);
    text-wrap: wrap;
    background-color:
        rgba(41, 71, 162, 0.71);
    text-align: center;
    padding: 5px;
    box-sizing: border-box;

}

.first-player-bet-score {
    font-size: 1rem;
    text-wrap: wrap;
    color: var(--white-color);
    font-weight: 600;

}

.first-player-win-amount {
    font-size: 0.8rem;
    text-wrap: wrap;
    color: var(--white-color);
    margin-top: 3px;
}


.second-player-bet-details {
    width: 32%;
    text-wrap: wrap;
    background-color:
        rgba(41, 71, 162, 0.71);
    text-align: center;
    padding: 5px;
    box-sizing: border-box;

}

.second-player-bet-score {
    font-size: 1rem;
    text-wrap: wrap;
    color: var(--white-color);
    font-weight: 600;

}

.second-player-win-amount {
    font-size: 0.8rem;
    text-wrap: wrap;
    color: var(--white-color);
    margin-top: 3px;
}


.bet-status {
    width: 32%;
    height: 55px;
    text-wrap: wrap;
    background-color: #a2afd7;
    text-align: center;
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    color: var(--white-color);
    box-sizing: border-box;
}