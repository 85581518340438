.Display_Flex {
    display: flex;
}

.Flex_Direction_Column
{
    flex-direction: column;
}

.Justify_Content_Space_Between {
    Justify-content: space-between;
}

.Align_Items_Center {
    align-items: center;
}

.wd-50 {
    width: 50%;
}

.wd-25 {
    width: 25%;
}

.wd-75 {
    width: 75%;
}


.wd-100 {
    width: 100%;
}

.wd-20 {
    width: 20%;
}

.wd-80 {
    width: 80%;
}

/* WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    /* Set the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
    background-color: 
    #C6AB52;
    /* Set the color of the scrollbar thumb */
    border-radius: 6px;
    /* Set the border radius of the scrollbar thumb */
}

::-webkit-scrollbar-track {
    background-color: #000;
    /* Set the color of the scrollbar track */
}