.register-user {
    display: flex;
    justify-content: center;
    margin-top: 75px;
}

.register-otp-section{
    height: 80vh;
}

.register-user-span {
    font-weight: 500;
    font-size: 20px;
}

.reg-user-bg {
    width: 100%;
}

.register-img {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.register-textfield-outer {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.register-textfield-inner {
    width: 35%;
    margin-bottom: 15px;
}

.register-outer-svg {
    display: flex;
    justify-content: center;
}

.register-inner-svg {
    display: flex;
    align-items: center;
    gap: 30px;
}

.register-svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.register-svg-span {
    color: green;
}

.register-checkbox {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.register-input-checkbox {
    width: 20px;
    height: 20px;
}

.register-w {
    width: 25%;
}

.register-outer-btn {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.register-main-btn {
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    background: #000;
}

.register-account {
    display: flex;
    justify-content: center;
    margin: 20px;
}

@media (max-width: 600px) {
    .register-textfield-inner {
        width: 70%;
        margin-bottom: 15px;
    }

    .register-w {
        width: 65%;
    }
}



/* Register OTP Page */

.register-otp {
    display: flex;
    justify-content: center;
    font-weight: 600;
    margin-top: 85px;
}

.register-otp-span {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.register-otpinput {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.register-otp-clock {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    gap: 10px;
}

.reg-clock {
    width: 20px;
    height: 20px;
}

.reg-confirm {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;
}