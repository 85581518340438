@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Work+Sans:wght@100..900&display=swap');

:root {
    --inplay-sectin-header-color: #2947A2;
    --font-family: "Inter", sans-serif;
    --inplay-section-header-logo-text-color: #ffffff;
    --inplay-header-logo-bg-color: #D6DF3C;
    --white-color: #ffffff;
    --total-open-bets-box-color: #D6DF3C;
    --sports-card-sports-name: #333333;
    --comman-sports-table-border-color: #D9D9D9;
    --sport-score-details-color: #2947A2;
    --match-team-name-color: #333333;

}




.dt-sports-table-cricket-match-card {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: 1px solid var(--comman-sports-table-border-color);
    border-left: 1px solid var(--comman-sports-table-border-color);
   
}


.dt-team-bet-link {
    text-decoration: none !important;
}
.dt-cricket-match-target {
    font-size: 10px;
    line-height: 12px;
    font-weight: 400;
    margin-top: 3px;
    font-family: var(--font-family);
    color: var(--sport-score-details-color);
}

.dt-cricket-first-player-bet-score {
  
    word-wrap: break-word;
    /* For legacy support */
    overflow-wrap: break-word;
    /* For modern browsers */
    font-size: 14px;
    text-wrap: wrap;
    color: var(--white-color);
    font-weight: 600;
    text-wrap: wrap;
    box-sizing: border-box;

}

.dt-cricket-first-player-win-amount {
    word-wrap: break-word;
    /* For legacy support */
    overflow-wrap: break-word;
    /* For modern browsers */
    font-size: 11px;
    text-wrap: wrap;
    color: var(--white-color);
    margin-top: 3px;
    box-sizing: border-box;

}
.dt-cricket-first-player-bet-score,
.dt-cricket-first-player-win-amount{
    max-width: 100%;
   
}

.dt-cricket-second-player-bet-score {
    word-wrap: break-word;
    /* For legacy support */
    overflow-wrap: break-word;
    /* For modern browsers */
    font-size: 14px;
    text-wrap: wrap;
    color: var(--white-color);
    font-weight: 600;
    box-sizing: border-box;

}

.dt-cricket-second-player-win-amount {
   
    word-wrap: break-word;
    /* For legacy support */
    overflow-wrap: break-word;
    /* For modern browsers */
    font-size: 11px;
    text-wrap: wrap;
    color: var(--white-color);
    margin-top: 3px;
    box-sizing: border-box;
}

.dt-cricket-second-player-bet-score,
.dt-cricket-second-player-win-amount{
    max-width: 100%;
    overflow: auto;
}

.dt-cricket-second-player-bet-score::-webkit-scrollbar-thumb,
.dt-cricket-second-player-win-amount::-webkit-scrollbar-thumb{
    display: none;
}






.dt-sport-table {
    width: 100%;
    margin-top: 10px;
}


.dt-match-score-and-team-details-section {
    width: 53%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
}

.dt-match-score-details {
    flex: 1;
    border: 1px solid var(--comman-sports-table-border-color);
    border-left: 0px;
    padding: 5px;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 0px;
    border-top: 0px;
}

.dt-total-over {
    font-size: 10px;
    line-height: 12px;
    font-weight: 400;
    font-family: var(--font-family);
    padding-top: 0px;
    color: var(--sport-score-details-color);
}


.dt-current-team-score {
    font-size: 10px;
    line-height: 12px;
    font-weight: 400;
    margin-top: 3px;
    font-family: var(--font-family);
    color: var(--sport-score-details-color);
}

.dt-upcoming-match-name {
    font-size: 0.8rem;
    line-height: 13px;
    font-weight: 600;
    font-family: var(--font-family);
    color: var(--match-team-name-color);
    padding-top: 0px;
    margin-left: 5px;
}

.dt-upcoming-match-date {
    font-family: var(--font-family);
    font-size: 0.8rem;
    color: var(--sport-score-details-color);
    text-wrap: wrap;
    word-wrap: break-word;
    /* For legacy support */
    overflow-wrap: break-word;

}

.dt-match-teams {
    flex: 3;
    height: 55px;
    max-height: 55px;
    padding-left: 4px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-right: 1px solid var(--comman-sports-table-border-color);
    padding-right: 5px;
    padding-top: 0px;
    text-decoration: none;
}



.dt-teams .dt-team1 {
    font-size: 0.8rem;
    line-height: 13px;
    font-weight: 600;
    font-family: var(--font-family);
    color: var(--match-team-name-color);
    margin-left: 4px;
}

.dt-teams .dt-team2 {
    font-size: 0.8rem;
    line-height: 13px;
    font-weight: 600;
    font-family: var(--font-family);
    color: var(--match-team-name-color);
    position: relative;
    margin-top: 4px;
    margin-left: 10px;
}

.dt-teams .dt-team2::before {
    content: "";
    width: 6px;
    height: 6px;
    position: absolute;
    top: 50%;
    left: -10px;
    transform: translateY(-50%);
    background-color: var(--inplay-sectin-header-color);
    border-radius: 50%;
}

.dt-live-icon-image .dt-live-icon {
    color: #2947A2;
}



/* Bet boxes section --------------------------- */
.dt-bets-boxes {
    width: 47%;
    display: flex;
    justify-content: space-between;
}

.dt-first-player-bet-details-boxes {
    flex: 1;
    display: flex;
    justify-content: space-between;
    max-height: 55px;
}

.dt-first-player-bet-details-box1 {
    flex: 1;
    max-height: 55px;
    border-right: 1px solid var(--comman-sports-table-border-color);
    background-color: #0F36AC;
    text-align: center;
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 2px;
}

.dt-first-player-bet-details-box2 {
    flex: 1;
    max-height: 55px;
    border-right: 1px solid var(--comman-sports-table-border-color);
    background-color: #E85D4A;
    text-align: center;
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 2px;
}


.dt-second-player-bet-details-boxes {
    flex: 1;
    display: flex;
    justify-content: space-between;
    max-height: 55px;
}

.dt-second-player-bet-details-box1 {
    flex: 1;
    max-height: 55px;
    border-right: 1px solid var(--comman-sports-table-border-color);
    background-color: #0F36AC;
    text-align: center;
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 2px;
}

.dt-second-player-bet-details-box2 {
    flex: 1;
    max-height: 55px;
    border-right: 1px solid var(--comman-sports-table-border-color);
    background-color: #E85D4A;
    text-align: center;
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 2px;
}

.dt-second-player-bet-details {
    flex: 1;
    max-height: 55px;
    background-color: #0F36AC;
    text-align: center;
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}




.dt-bet-status {
    display: flex;
    flex: 1;
}

.dt-first-bet-status1 {
    flex: 1;
    max-height: 55px;
    word-wrap: break-word;
    /* For legacy support */
    overflow-wrap: break-word;
    /* For modern browsers */
    background-color: #5091F1;
    text-align: center;
    padding: 5px;
    box-sizing: border-box;
    font-size: 40px;
    color: var(--white-color);
    box-sizing: border-box;
    margin-right: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dt-first-bet-status2 {
    flex: 1;
    max-height: 55px;
    word-wrap: break-word;
    /* For legacy support */
    overflow-wrap: break-word;
    /* For modern browsers */
    background-color: #5091F1;
    text-align: center;
    padding: 5px;
    box-sizing: border-box;
    font-size: 40px;
    color: var(--white-color);
    box-sizing: border-box;
    margin-right: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dt-live-icon-image {
    width: 19px;
    height: 19px;
    margin-right: 6px;
}

.dt-live-icon-image img {
    width: 100%;
    height: 100%;
}


/* -------------FOOT BALL --------------- */

.dt-left-score {
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--sport-score-details-color);
    font-family: var(--font-family);

}

.dt-right-score .dt-right-score1 {
    font-size: 0.8rem;
    font-weight: 400;

    color: var(--sport-score-details-color);
    font-family: var(--font-family);
}

.dt-right-score .dt-right-score2 {
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--sport-score-details-color);
    font-family: var(--font-family);
}