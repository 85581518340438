.topgms {
    /* background: #fff; */
    margin-top: 15px;
    padding: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.topgms1 {
    /* background: #fff; */
    margin-top: 8px;
    padding: 2px;
}

.spngame {
    font-weight: 500;
    font-size: 17px;
    margin-left: 8px;
    color: black;
}

.arrowwhite {
    width: 21px;
    margin-left: 10px;
    height: 21px;
}

.component-heading-block {
    background-color: #000;
    width: 5px;
    height: 20px;
}