button
{
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    
}

.Disabled_Button
{
    background-color: #d3d3d3 !important;
    color: #a9a9a9 !important;
}

.get-started-btn {
    margin-left: 3px;
    background: transparent;
    color: #000;
    border: 1px solid #b99c49;
    border-radius: 15.5px;
    padding: 8px 16px;
    white-space: nowrap;
    transition: 0.3s;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
    background: #C6AB52;
}
.get-started-btn.header-button{
    color: #fff !important;
    background: transparent !important;
}
.fill-color{
    background-color: #b99c49;
    color: #044015;
}
@media (max-width: 992px) {
    .get-started-btn {
        padding: 4px 12px;
        font-size: 11px;
        border-radius: 16px;
        font-size: 12px;
        font-weight: 400;
    }

    .get-started-btn-small {
        padding: 4px 12px;
        font-size: 11px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 600;
    }
}

@media screen and (min-width:392px) and (max-width:768px) {
    .get-started-btn {
        margin-left: 2px;
        background: transparent;
        color: #000;
        border: 1px solid #b99c49;
        border-radius: 28px;
        padding: 8px 11px;
        white-space: nowrap;
        transition: 0.3s;
        font-size: 10px;
        font-weight: 600;
        display: flex;
        align-items: center;
        text-decoration: none;
        cursor: pointer;
        margin-left: 2px;
        margin-right: 2px;
    }
    .fill-color{
        background-color: #b99c49;
        color: #044015;
    }
}


@media screen and (min-width:340px) and (max-width:390px){
    .get-started-btn {
        margin-left: 2px;
        background: #C6AB52;
        color: #000;
        border: 1px solid #b99c49;
        border-radius: 28px;
        padding: 8px 11px;
        white-space: nowrap;
        transition: 0.3s;
        font-size: 13px;
        font-weight: 600;
        display: flex;
        align-items: center;
        text-decoration: none;
        cursor: pointer;
        margin-left: 2px;
        margin-right: 2px;
    }
    .fill-color{
        background-color: #b99c49;
        color: #044015;
    }
}




.get-started-btn-small {
    background: #C6AB52;
    color: #000;
    border-radius: 28px;
    padding: 8px 16px;
    white-space: nowrap;
    transition: 0.3s;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    height: 35px;
    margin: 5px !important;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
}
.get-started-btn-small.header-button{
    color: #fff;
}
.Approved {
    background: #047b44;
    color: #fff;
    border-radius: 28px;
    padding: 8px 16px;
    white-space: nowrap;
    transition: 0.3s;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    height: 35px;
    margin: 5px !important;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
}

.Removed {
    background: #ec9800;
    color: #fff;
    border-radius: 28px;
    padding: 8px 16px;
    white-space: nowrap;
    transition: 0.3s;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    height: 35px;
    margin: 5px !important;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
}



.gen-image {
    width: 15px;
    margin-left: 5px;
}

.back-button {
    text-align: center;
    display: inline;
    width: 100%;
    text-decoration: none;
    padding: 0 8px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
}

.profile-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    margin-inline-start: 12px;
}

.profile-button-text {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
}

.profile-button-icon {
    width: 30px;
    height: 30px;
}

.profile-button-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    margin-left: 3px;
    background-color: #FFF;
    padding: 0 5px;
    margin: 0 8px;
    min-width: 50px;
}

.profile-button-text-mobile {
    color: #000;
    font-size: 9px;
    font-weight: 600;
    margin-bottom: 0;
}

.profile-button-icon-mobile {
    width: 10px;
    height: 10px;
    margin-top: 2px;
}

.Dangerous_Button
{
    background: #DD3333;
    color: #FFFFFF;
}