.Mini_Loader_Container
{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.Mini_Loader 
{
	width: 4rem;
	height: 4rem;
	border-radius: 50%;
	border: 0.375rem solid #D8E150;
	position: relative;
}

.Mini_Loader::before 
{
	content: '';
	position: absolute;
	top: -0.375rem;
	left: -0.375rem;
	width: 4rem;
	height: 4rem;
	border-radius: 50%;
	border: 0.375rem solid transparent;
	border-top-color: #000;
	animation: Spinning 1s linear infinite;
}
  
@keyframes Spinning 
{
	0% 
	{
		transform: rotate(0deg);
	}

	100% 
	{
		transform: rotate(360deg);
	}
}