#header {
    transition: all 0.5s;
    z-index: 997;
    padding: 0 !important;
    background-color: #000;
    
  }
  .header-logo-containe{
    display: flex;
    align-items: center;
  }

  .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    height: 60px;
    display: flex;
    justify-content: center;
  }
.header-profile-icon-and-profile-details{
    display: flex;
    align-items: center;
}
.header-container{
    display: flex;
    align-items: center !important;
    justify-content: space-between;
}
.header-button-container{
    display: flex;
    align-items: center !important;
}
.profile-details{
    margin-left: 3px;
   
}
.profile-name{
    font-size: 12px;
    color: #FFFFFF;
    font-weight: 500;
    font-family: var(--font-family);
}
.balance{
    font-size: 12px;
    color: #FFFFFF;
    font-weight: 500;
    font-family: var(--font-family);
}
.ruppee-icon{
    margin-right: 5px;
}
.side-menu-icon{
    margin-left: 5px;
}
.side-menu-icon{
    display: none;
    margin-right: 5px;
    margin-bottom: 5px;
}

.profile-icon{
    width: 47px;
    height: 51px;
    margin-right: 5px;
    cursor: pointer;
}
.kuberbet-header-logo{
width: 240px;
height: 45px;
margin-left: 10px;
}




@media screen and (max-width:600px) {
    .side-menu-icon{
        display: block;
    }
    .kuberbet-header-logo {
        width: 150px;
        height: 30px;
    }
  
    .header-container{
        width: 98%;
        margin: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .header-button-container{
        display: flex !important;
        flex-wrap: nowrap;
        align-items: center !important;
        margin-left: 10px;
        justify-content: space-between !important;
    }
    .profile-details{
        margin-left: 7px;
    }
    .profile-icon{
        width: 34px;
        height: 37px;
        margin-right: 0px;
       
    }
    
    .profile-name{
        font-size: 9px;
        color: #FFFFFF;
        font-weight: 500;
        font-family: var(--font-family);
        display: grid;
        
    }
    .balance{
        font-size:9px;
        color: #FFFFFF;
        font-weight: 500;
        font-family: var(--font-family);
    }
    .ruppee-icon{
        margin-right: 1px;
    }
}

@media screen and (min-width:355px) and (max-width:390px){
    .header-button-container{
        display: flex !important;
        flex-wrap: nowrap;
        align-items: center !important;
        margin-left: 3px;
        justify-content:space-between;
    }
    .kuberbet-header-logo{
        width: 150px;
        height: 45px;
    }
      
    .profile-details{
        margin-left: 2px;
    }
    .profile-icon{
        width: 34px;
        height: 37px;
       margin-right: 0px;
    }
    
    .profile-name{
        font-size: 8px;
        color: #FFFFFF;
        font-weight: 500;
        font-family: var(--font-family);
        display: grid;
        
    }
    .balance{
        font-size:9px;
        color: #FFFFFF;
        font-weight: 500;
        font-family: var(--font-family);
    }
    .ruppee-icon{
        margin-right: 1px;
    }
}

@media screen and (min-width:340px) and (max-width:358px){
    .header-button-container{
        display: flex !important;
        flex-wrap:nowrap;
        align-items: center !important;
        margin-left: 5px !important;
        justify-content: space-between;
    }
    .profile-details{
        margin-left: 2px;
    }
    .profile-icon{
        width: 34px;
        height: 37px;
        margin-right: 0px;
    }
    
    .profile-name{
        font-size: 9px;
        color: #FFFFFF;
        font-weight: 500;
        font-family: var(--font-family);
        display: grid;
       
    }
    .balance{
        font-size:9px;
        color: #FFFFFF;
        font-weight: 500;
        font-family: var(--font-family);
    }
    .ruppee-icon{
        margin-right: 1px;
    }
}