.game-lobby-banners-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 25px;
}

.game-lobby-image {
    width: 280px;
    margin-inline-end: 10px;
    margin-top: 10px;
    cursor: pointer;
}

.game-lobby-row{
    flex-direction: row;
    display: flex;
}

.swiper-container{
    flex-direction: row !important; 
    display: flex !important;
    flex-wrap: wrap !important;
    margin: 15px;
}

.innerDiv{
    flex-direction: row;
    display: flex;
}

#game-swiper .swiper-pagination-bullet {
    display: inline-block;
    height: 2px;
    width: 20px;
    -webkit-appearance: none;
}

#game-swiper .swiper-pagination {
    position: relative;
    margin-top: 5px;
}

.indian-earning-game{
    width: 300px;
    height: 200px;
    margin: 5px;
    cursor: pointer;
    border-radius: 10px;
}

.indian-earn-mobile{
    height: 120px !important;
}

@media (max-width: 1550px) {
    .game-lobby-image {
        width: 280px;
        margin-inline-end: 5px;
        margin-top: 5px;
        cursor: pointer;
    }
    .game-lobby-row{
        margin: 0px;
    }

}

@media (max-width: 1050px) {
    .game-lobby-image {
        width: 100%;
        margin-inline-end: 5px;
        margin-top: 5px;
        cursor: pointer;
    }
    .game-lobby-row{
        margin: 5px;
    }

}

@media (max-width: 1000px) {
    .game-lobby-image {
        width: 100%;
        margin-inline-end: 5px;
        margin-top: 5px;
        cursor: pointer;
    }
    .game-lobby-row{
        margin: 5px;
    }

}

@media (max-width: 830px) {
    .game-lobby-image {
        width: 100%;
        margin-inline-end: 5px;
        margin-top: 5px;
        cursor: pointer;
    }
    .game-lobby-row{
        margin: 5px;
    }

}

@media (max-width: 780px) {
    .game-lobby-image {
        width: 95%;
        margin-inline-end: 5px;
        margin-top: 5px;
        cursor: pointer;
    }
    .game-lobby-row{
        margin: 5px;
    }

}


@media (max-width: 600px) {
    .game-lobby-image {
        width: 95%;
        margin-inline-end: 5px;
        margin-top: 5px;
        cursor: pointer;
    }
    #game-swiper{
        margin-bottom: 15px;
    }
    .swiper-container{
        margin: 5px;
    }
}

@media (max-width: 365px) {
    .game-lobby-image {
        width: 95%;
        margin-inline-end: 5px;
        margin-top: 5px;
        cursor: pointer;
    }

}


@media (max-width: 290px) {
    .game-lobby-image {
        width: 95%;
        margin-inline-end: 5px;
        margin-top: 5px;
        cursor: pointer;
    }

}