

#header .logo {
  font-size: 30px;
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

#header .logo a {
  color: #111111;
}

#header .logo a span {
  color: #e03a3c;
}

#header .innrhd {
  position: fixed;
  z-index: 1000;
  width: 100%;
  max-width: 100%;
  border-radius: 0;
}



.clrBoth {
  width: 100%;
  height: 0;
  line-height: 0;
  clear: both;
}

.logo a img {
  width: 200px;
  margin: 15px 20px;
}

.tpDV {
  text-align: center;
  display: block;
  /* width: 100%;
  -webkit-box-shadow: -2px 9px 5px -3px rgba(0, 0, 0, 0.86);
  -moz-box-shadow: -2px 9px 5px -3px rgba(0, 0, 0, 0.86);
  box-shadow: -2px 9px 5px -3px rgba(0, 0, 0, 0.86); */
  margin-bottom: 5px;
}

.tpNv1 {
  text-align: left !important;
}

.tpmar {
  margin-top: 0px;
}

.withdraw-size {
  padding: 15px;
}

.tpDV p {
  margin-bottom: 2px;
  margin-top: 0;
}

.tpNv {
  text-align: center;
  display: inline;
  width: 100%;
  text-decoration: none;
  padding: 0 8px;
  color: #fff;
  font-size: 14px;
}

.popup-hdr {
  background-color: white;
  position: absolute;
  top: 13%;
  /* height: 208px; */
  width: 45%;
  right: 0%;
  z-index: 9999;
}

.popup-a {
  color: black;
  text-decoration: none;
  padding-left: 20px;
  font-weight: 600;
  font-size: 14px;
}

.pop {
  line-height: 40px;
}

.point {
  color: red;
  margin-bottom: 0px;
}

.headerdv {
  margin-top: 10px;
}

.borderline {
  border-bottom: 1px solid #ddd;
}

.wlogo {
  width: 210px;
  height: auto;
  margin-left: 15px;
}

.log-Main {
  text-align: right;
}

a.log {
  color: #9062d2;
}

a.log:hover {
  color: #fff;
}

.log-Main a {
  font-size: 20px;
  margin: 0 5px;
  text-decoration: none;
  /* font-weight: bold; */
  font-family: "Roboto";
}

a.sign {
  color: #fff;
}

.imgicon {
  width: 20px;
  margin-left: 30px;
}

/* .get-started-btn {
  margin-left: 7px;
  background: #D8E150;
  color: #30469C;
  border-radius: 0 !important;
  padding: 8px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
} */

img.depositbtn {
  width: 16px;
  margin-right: 5px;
  margin-top: -4px;
}

img.userbtn {
  width: 15px;
  margin-right: 5px;
  margin-top: -2px;
}

.lloginBTn {
  background: linear-gradient(to right, #436ad8 0, #b264b9 100%) !important;
  border-radius: 3px !important;
  border: 0;
  color: #fff !important;
}

.active1 {
  border-bottom: 3px solid #a365bd;
}

.newheader {
  height: 35px !important;
}

.headerpart {
  float: right;
  margin-right: 10px;
}

.sidenav1 a {
  color: #6A6A6A ;
  transition: color 0.3s;
}
.sidenav1 a:hover {
  color:#31469C
}

.logbox {
  display: flex;
  float: right;
  /* gap: 0.5rem; */
  /* margin-top: 5px; */
}

.hamburgr {
  height: 25px;
  width: 25px;
}

.app-btn {
  margin: 5px 15px !important;
  cursor: pointer;
  float: right;
}

@media (min-width: 1600px) {

  .headerdv {
    /* margin-top: 25px !important; */
    /* margin-left: 5rem !important; */
  }

}

@media (min-width: 1200px) {
  .ovl-1 {
    display: none;
  }

  .desk-menu {
    display: none !important;
  }

  .app-btn {
    display: none;
  }

  /* #header {
    height: 50px;
  }
  .testimonials {
    margin-top: 50px !important;
  } */
  .side-header {
    margin-top: 15px !important;
  }

  .headerdv {
    margin-top: 25px !important;
    margin-left: 5rem !important;
  }

}


.log-btn {
  color: #000 !important;
  background: #C6AB52 !important;
  border: none !important;
  cursor: pointer;
  border-radius: 0 !important;
}

.dnone {
  display: flex;
}

.reg {
  margin-left: -8px !important;
}

.header-logo {
  width: 200px;
  height: 45px;
}

.new-logo {
  width: 120px;
}

.nav-height {
  height: 100%;
}

@media (max-width: 1200px) {
  a.log {
    background: linear-gradient(to right, #436ad8 0, #b264b9 100%) !important;
    border-radius: 0 !important;
    border: 0;
    color: #fff !important;
    margin: 0 4px 0 0;
    padding: 4px 7px;
    font-size: 10px;
  }

  a.sign {
    margin: 0 4px 0 0;
    padding: 7px 9px;
    font-size: 11px;
    border-radius: 0;
    background: #dddddd;
    color: #000;
    font-weight: 600;
    display: inline-block;
    padding-bottom: 5px;
  }

  .tpDV {
    margin-bottom: 0;
  }

  .logo a img {
    width: 160px;
    height: 25px;
    margin: 0;
  }

  .ovl {
    position: fixed;
    top: 0px;
    bottom: 0px;
    width: 60%;
    background: #fff;
    overflow: auto;
    z-index: 9999;
  }

  .sidenav1 {
    width: 100% !important;
    background-color: #000;
    margin: 5px 0;
  }

  .dnone {
    display: none !important;
  }

  .download-btn {
    margin: 3px 12px !important;
    border-radius: 0 !important;
  }
}

@media (max-width: 440px) {
  .wlogo {
    width: 165px;
    height: 30px;
    margin-top: 5px;
    margin-left: 5px;
  }

  .header-logo {
    width: 100px;
    height: 30px;
  }

  .fixed-top {
    height: 72px;
  }
}

@media (max-width: 380px) {
  .wlogo {
    width: 150px;
    height: 28px;
    margin-top: 7px;
  }
}

@media (max-width: 360px) {

  .header-logo {
    width: 90px;
    height: 25px;
  }

  .menu-icon {
    width: 16px;
    height: 16px;
    margin-inline-end: 3px;
  }

}

@media screen and (max-width:768px) {
  .sidenav1 {
   
    margin: 0px 0 !important;
  }

}

.mobile-top-header {
  margin-right: 10px;
}

.header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.header-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header-logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.menu-icon {
  width: 19px;
  height: 19px;
  margin-inline-end: 6px;
}

.header-logo-container-mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
}


.game-header {
  background: #000;
  height: 35px;
  display: flex;
  align-items: center;
  width: 100%;
}

.game-header-back-button {
  width: 10%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.game-header-homepage-button {
  width: 5%;
  display: flex;
  justify-content: center;
  margin-left: 5px;
  cursor: pointer;
}

.game-header-home-button {
  width: 90%;
  display: flex;
  justify-content: center;
}

.game-header-back-button-desktop {
  width: 5%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.game-header-homepage-button-desktop {
  width: 5%;
  display: flex;
  justify-content: center;
  margin-left: -24px;
  cursor: pointer;
}

.game-header-home-button-dekstop {
  width: 95%;
  display: flex;
  justify-content: center;
}

.feedback-header {
  background: #000;
  height: 50px;
  display: flex;
  align-items: center;
  width: 100%;
}

.feedback-header-back-button {
  width: 12%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer
}

.feedback-header-text {
  width: 73%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.feedback-header-text-mobile-deposit {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.feedback-header-back-button-desktop {
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.feedback-header-text-desktop {
  width: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bet-history-header {
  background: #000;
  height: 50px;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #636363;
}

.filter-bet {
  width: 25px;
  height: 20px;
  float: right;
  margin: 4px 5px;
}


.bet-history-header-back-button {
  width: 12%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer
}

.bet-history-header-filter-button {
  width: 12%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: -40px;
}

.bet-history-header-filter-button-balance {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: -40px;
}
.bonus-wallet-header{
  background: #000;
  height: 60px;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #636363;
}
.bonus-wallet-header-filter-button {
  display: flex;
  margin-inline-end: 5px;
  justify-content: center;
  align-items: center;
}

.bonus-wallet-header-filter-button-desktop {
  display: flex;
  margin-inline-end: 10px;
  justify-content: center;
  align-items: center;
}
.bonus-screen-header-text-desktop{
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bonus-screen-header-text-mob{
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.bet-history-header-text {
  width: 73%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bet-history-header-back-button-desktop {
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.bet-history-header-filter-button-desktop {
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.bet-history-header-text-desktop {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}



.depo-mobile-copy-button {
  color: #fff;
  padding: 0px 8px;
  font-family: "Roboto Slab";
  font-size: 15px;
  display: flex;
  align-items: center;
  margin-top: -11px;
}

@media (max-width: 1200px) {
  .ovl, .ovlr {
      background: #fff;
      bottom: 0;
      overflow: auto;
      position: fixed;
      top: 0;
      z-index: 9999;
  }
}

@media (max-width: 1200px) {
  .ovlr {
      width: 100%;
  }
}