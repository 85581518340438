
:root{
    --openbets-list-bg:#2947a2;
    --font-family: "Inter", sans-serif;
    --matched-bet-message-color:333333;
    --black-color:#333333;
    --white-color:#ffffff;
    --openbets-matched-bets-column-labels-bg:#D6DF3C;
}

.openbets-section{
    width: 100%;
    height: auto !important;
    margin-top: 0px;
}
.openbets-section-container{
    width: 100%;
}
.openbets-matchedbets-column-labels{
    width: 100%;
    height: 55px;
    background-color: var( --openbets-matched-bets-column-labels-bg);
   

}
.openbets-matchedbets-column-labels .wrapper{
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.openbets-matched-inning-label{
    width: 60%;
    font-size: 1.1rem;
    font-weight: 600;
    line-height: 1.5rem;
    font-family: var(--font-family);
}
.openbets-matched-odds-and-stake-label{
    width: 40%;
    display: flex;
    justify-content: space-between;
}
.openbets-matched-odds-label{
    width: 49%;
    font-size: 1.1rem;
    font-weight: 600;
    line-height: 1.5rem;
    font-family: var(--font-family);
    text-transform: uppercase;
    text-align: center;
}
.openbets-matched-stake-label{
    width: 49%;
    font-size: 1.1rem;
    font-weight: 600;
    line-height: 1.5rem;
    font-family: var(--font-family);
    text-transform: uppercase;
    text-align: center;
}

/* Matched bets details ---------------------- */
/* .openbets-matched-bets-list{
    width: 100%;
}
.openbets-matched-bet-details-card{
    width: 100%;
    height: 55px;
    background-color: #f8d2cc;
    margin-top: 0px;
}
.openbets-matched-bet-details-card-wrapper{
    width: 95%;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.openbets-matched-bet-inning-detail{
   
    font-size: 0.8rem;
    font-weight: 600;
    font-family: var(--font-family);
    line-height: 1.6rem;
}

.openbets-matched-odds-and-stake-details{
   
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
} */
/* .matched-odds-details{
   
    text-align: center;
    font-size: 0.8rem;
    font-weight: 600;
    font-family: var(--font-family);
    line-height: 1.5rem;
}
.matched-stake-details{
   
    text-align: center;
    font-size: 0.8rem;
    font-weight: 600;
    font-family: var(--font-family);
    line-height: 1.5rem;
} */

.open-bets-section{
    width: 100%;
}

.open-bets-header{
    width: 100%;
   padding: 0px 5px 0px;
   height: 50px;
   display: flex;
   align-items: center;
    background-color: var( --openbets-matched-bets-column-labels-bg);
    font-weight: 500;
    color: #333;
    font-size: 1.1rem;
}


.data-th{
    width: 100%;
    background-color: #333;
}
.data-th-container{
    width: 99%;
    margin: auto;
    display: flex;
    justify-content: space-between;
}
.data-label{
    width: 25%;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    font-family: var(--font-family);
    line-height: 1.5rem;
    color: #fff;
}

.open-bets-data-body{
    width: 100%;
    background-color: #f8d2cc;
}
.data-row{
    width: 99%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
   
}
.data-td{
    width: 25%;
    text-align: center;
    font-size: 0.9rem;
    font-weight: 400;
    font-family: var(--font-family);
    line-height: 1.5rem;

}


@media screen and (max-width:768px) {
    


.open-bets-header{
    width: 100%;
   padding: 0px 5px 0px;
   height: 50px;
   display: flex;
   align-items: center;
    background-color: var( --openbets-matched-bets-column-labels-bg);
    font-weight: 500;
    color: #333;
    font-size: 1rem;
}

.data-label:nth-child(1){
    width: 45%;
    text-align: center;
    font-size: 0.9rem;
    font-weight: 500;
    font-family: var(--font-family);
    line-height: 1.5rem;
    color: #fff;
}

.open-bets-data-body{
    width: 100%;
    background-color: #f8d2cc;
}
.data-row{
    width: 99%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
   
}
.data-td:nth-child(1){
    width:45%;
    text-align: center;
    font-size: 0.6rem;
    font-weight: 400;
    font-family: var(--font-family);
    line-height: 0.9rem;

}
}


.no-open-bets-message{
    text-align: center;
    padding-top: 100px;
}