
.new-exchange {
  width: 40%;
  margin: auto;
  display: flex;
  padding: 10px 0px 10px 0px;
  justify-content: space-evenly;
  background-color: transparent;
  margin-top: 12px;
}
.exchange-icon{
  width: 65px;
  height: 65px;
}
.spn-clr {
  color: #231E28;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}
.game-name {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}
.game-name  .link{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}


@media screen and (max-width:600px) {
  .new-exchange {
    width: 100%;
    display: flex;
    padding: 10px 0px 10px 0px;
    justify-content: space-evenly;
    
    margin-top: 0px;
  }
  .exchange-icon{
    width: auto;
    height:auto;
  }
  .spn-clr {
   
    font-weight: 500;
    font-size: 12px;
  }
  .game-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
  }
  
}

