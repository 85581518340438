form {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: 50px auto; */
  max-width: 400px;
}

.logform {
  margin: auto;
}

form.log {
  margin: 50px auto;
}



.modal-body {
  padding: 0;
}

label {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
  max-width: 300px;
  color: black;
  font-weight: 600;
  font-size: 14px;
}

.input-search {
  padding: 10px 60px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid grey;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  margin-top: 5px;
  width: 100%;
}

.logbt {
  padding: 3px 70px !important;
  border-radius: 1rem !important;
}

.login {
  background: #fff;
}

.loginbtn {
  background: linear-gradient(to right, #436ad8 0, #b264b9 100%);
  border-radius: 10px;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 15px;
  cursor: pointer !important;
}

.id-join {
  width: 100%;
  text-align: center;
}
.msgId {
  font-size: 11px;
  display: inline-block;
  margin-top: 2px;
  background-color: #01143c;
  padding: 0px 8px;
  text-decoration: none;
  font-family: "Roboto";
}
a.eye {
  float: right;
  position: relative;
  top: -35px;
  right: 10px;
}

a.msgId {
  color: #fff;
  border: 1px solid #ddd;
  border-radius: 0;
}

.textfld {
  font-family: "Roboto" !important;
}

.spnlg {
  font-family: "Roboto";
  color: #01143c;
}

.login1 {
  margin: 20vh 0;
}

h2.log {
  margin-bottom: 25px;
}

.login-second {
  padding: 0px 15px;
  background-color: #fff;
  /* padding-bottom: 25px; */
}

.hdr {
  padding: 3px 15px !important;
  background: #000;
  color: #fff;
  font-family: "Roboto Slab";
}

.btn-close {
  background-color: white !important;
  margin-right: 2px !important;
  opacity: 0.9 !important;
  width: 6px !important;
  height: 6px !important;
}

.signbt1 {
  padding: 3px 50px !important;
  border-radius: 0 !important;
  width: 38% !important;
}

.MuiButtonBase-root {
  /* background: #01143c !important; */
  background: #C6AB52 !important;
  color: #000 !important;
  text-transform: capitalize !important;
  border-radius: 0 !important;
}

@media only screen and (max-width: 600px) {
  .login-second {
    padding: 0;
  }
}

@media only screen and (max-width: 290px) {
  .loginbtn {
    font-size: 10px;
  }
  .msgId {
    font-size: 15px;
  }
  .profile-list li a span {
    font-size: 10px;
  }
  .tpNv {
    font-size: 10px;
  }
  .logo a img {
    width: 110px;
  }
  .get-started-btn {
    font-size: 8px;
  }
  .social-media li {
    display: inline-block;
    width: 125px;
  }
  .social-media li a span {
    font-size: 13px;
  }
  .logou-bx .btn.btn-red {
    padding: 5px;
  }
  .evol-label {
    line-height: 16px;
    height: 20px !important;
  }
  .evol-label img {
    margin-top: 1px;
  }
  .list-logo {
    width: 15px;
  }
}

@media (max-width: 1200px) {
  a.eye {
    float: right;
    position: relative;
    top: -35px;
    right: 10px;
  }
  .row-mr .col {
    padding: 0 4px !important;
  }
}

@media (min-width: 1200px) {
  .row-mr .col {
    padding: 0 70px !important;
  }
  .login {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 600px) {
  form {
    /* margin: auto; */
    padding: 0 20px;
  }
  .logform {
    margin: auto;
  }

  label {
    max-width: 100%;
  }

  .input-search {
    width: 100%;
  }
}

@media only screen and (min-width: 540px) {
  .modal-content {
    width: 70%;
    margin: 0 80px;
  }
  .white-form-dv {
    padding-bottom: 70px !important;
  }
}

.inpsearch {
  padding: 6px 0px;
  margin-top: 1px;
  margin-right: 7px;
}
.captchanew {
  display: flex;
  padding-bottom: 10px;
  margin-top: -1rem;
}
