#preloader {
  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
}

#preloader img {
  height: 120px;
  width: 120px;
}


.loading-container {
  text-align: center;
  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(50px);
}

.logo {
  background: linear-gradient(25deg, #C6AB52, #000);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 4.8em;
  font-weight: bold;
}

.progress-circle {
  /* margin-top: 14px; */
  /* position: absolute; */
  width: 25em;
  height: 15em;
  /* border-radius: 50%; */
  /* border: 6px solid #C6AB52; */
}

/* .progress-circle::before {
  content: '';
  position: absolute;
  top: -6px;
  left: -6px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 6px solid transparent;
  border-top-color: #000;
  animation: spin 1s linear infinite;
} */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}