.betimage {
    width: 45px;
}

.nodata {
    margin-top: -25px;
    font-size: 12px;
}

.content-bet {
    padding: 0 !important;
    height: 25rem !important;
}

.bethis-card {
    display: flex;
    border-bottom: 1px solid #ddd;
    height: 32px;
}

.card-child {
    margin: -10px 10px;
}


.profile-bet {
    width: 21px;
    margin-top: -6px;
}

.loss-bet {
    margin: -10px 0;
}


.text-bethis {
    font-size: 16px;
    font-family: "Roboto";
}

.text-bethis1 {
    font-size: 11.5px;
    font-family: "Roboto";
    color: #90969b;
    margin-bottom: 0;
    line-height: 0;
}

.amount-bethis {
    float: right;
    margin: -41px 10px;
}

.date-bethis {
    margin: -10px 16px;
}

.betamount {
    float: right;
}

.bet-content-container {
    margin: 5px 10px;
    display: flex;
    flex-direction: column;

}

.bet-market-name {
    font-size: 14px;
    color: black;
    margin-bottom: 0;
    font-weight: 600;
    line-height: 18px;
}

.bet-date-container {
    margin-top: 20px;
}