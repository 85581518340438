.clrBoth {
	height: 0;
	line-height: 0;
	clear: both;
}

.scrolling-wrapper-casio {
	padding-bottom: 15px;
	display: flex !important;
	justify-content: center !important;
	margin-top: 2rem !important;
}

.searxhin:focus-visible {
	border: 0;
	outline: 0;
}

.providers-top-games-container {
	margin: 0 10px;
}

.crv-csn {
	padding: 3px !important;
}

.crv-csn img {
	border-radius: 8px;
}

.casOuter {
	background-color: #fff;
	padding: 25px 15px;
	padding-bottom: 0;
	margin-top: 4.5rem;
}

.card-block {
	background-color: blue;
}

.container1-casino {
	width: 100%;
	padding-right: 0;
	padding-left: 0;
}

.casnTb {
	width: 110px !important;
	max-width: 110px;
	padding: 0 8px;
	text-align: center;
	/* border-right: 1px solid #b7b6b6; */
	text-decoration: none;
	cursor: pointer;
}

.casnTb span {
	display: block;
	font-size: 13px;
	/* color: #575758; */
	color: #31469C;
	font-weight: 500;
	margin-top: 3px;
}

.casnTb img {
	/* width: 32px; */
	width: 50px;
}

.nortBor {
	border-right: 0 !important;
}

/* Search */
.container1 {
	width: 170px;
	height: 40px;
	background-color: #1e272e;
	border-radius: 14px;
	padding: 10px;
	float: right;
}

.sercottr {
	float: right;
	height: 18px;
	background: none;
	color: #f7f7f7;
	font-size: 14px;
	border-radius: 14px;
	outline: none;
	border: none;
	position: relative;
	opacity: 1;
	cursor: pointer;
	width: 160px;
	margin-right: -7px;
	top: -25px;
}

.icds {
	position: absolute;
	top: 10px;
	left: 5px;
	color: #767677;
}


.search__icon {
	box-sizing: border-box;
	font-size: 2.5rem;
	display: inline-block;
	margin-left: 0.8rem;
	margin-top: 0;
	cursor: pointer;
	position: absolute;
	color: #fa983a;
	transition: all 0.25s ease-in;
	padding: 0.7rem;
	border-radius: 50%;
}

.container:hover>.search__box {
	width: 85%;
	padding: 0 1rem;
}

.container:hover>.search__icon {
	background-color: #eee;
}


.searxhin {
	background-color: #c6c6c8;
	border: 0;
	height: 35px;
	padding: 5px;
	color: #000;
	padding-left: 40px;
	width: 100%;
}

span.search-icon {
	color: black;
	position: absolute;
	left: 7px;
	top: 6px;
}

.casinoMainDv {
	/* background: #ddd; */
	padding: 3px;
}

.member-img {
	cursor: pointer;
}

.casino-container {
	display: flex;
    justify-content: center;
    margin: 10px auto auto;
    width: 97%;
}

.casino-inner-container {
	width: 90%;
	display: flex;
	flex-direction: column;
}

.header-background-holder {
	/* margin-top: 60px; */
}

.bgcasino {
	width: 100vw;
	height: 20vw;
}

.bgcasino-mobile {
	width: 100vw;
	height: 30vw;
}

.category-tabs {
	display: flex;
	justify-content: center;
	margin-top: 30px;
}

.sorting {
	display: flex;
	justify-content: center;
}

.sorting span {
	font-weight: 500;
	font-size: 15px;
	color: #0D1D5C;
}

.btn-sort {
	display: flex;
	justify-content: center;
	gap: 10px;
	margin-top: 10px;
	margin-bottom: 1rem;
}

.btnsrt {
	background: #0D1D5C !important;
	color: #fff !important;
	border-radius: 5px !important;
	gap: 5px;
}

.btn-svg {
	width: 15px;
	height: 15px;
}

.gamelist {
	padding: 10px !important;
	margin-bottom: -10px;
}

.gamelist-img img {
	border-radius: 40px !important;
}

.casino-category-categories-list {
	margin: 20px 0;
	padding-bottom: 15px;
	display: flex;
	flex-direction: row;
	overflow: auto;
}

.casino-category-categories-list-desktop {
	margin: 20px 0;
	padding-bottom: 15px;
	display: flex;
	flex-direction: row;
	overflow: auto;
	justify-content: center;
}

.casino-category-icon-image {
	width: 50px;
	height: 50px;
}

.casino-cateogry-icon-selected-image {
	filter: opacity(0.2) drop-shadow(0 0 0 black);
}


.wrapper-inner {
	display: flex;
	max-width: 50%;
	overflow: auto;
	justify-content: center;
	padding-bottom: 10px;
}

@media (max-width: 1550px) {
	.wrapper-inner {
		display: flex;
		max-width: 100%;
		overflow: auto;
		justify-content: center;
		padding-bottom: 10px;
	}

}

@media (max-width: 1100px) {
	.wrapper-inner {
		display: flex;
		max-width: 50%;
		overflow: auto;
		justify-content: center;
		padding-bottom: 10px;
	}

}

@media (max-width: 1050px) {
	.wrapper-inner {
		display: flex;
		max-width: 53%;
		overflow: auto;
		justify-content: center;
		padding-bottom: 10px;
	}

}

@media (max-width: 1000px) {
	.wrapper-inner {
		display: flex;
		max-width: 50%;
		overflow: auto;
		padding-bottom: 10px;
	}

}

@media (max-width: 830px) {
	.wrapper-inner {
		display: flex;
		max-width: 50%;
		overflow: auto;
		padding-bottom: 10px;
	}

}

@media (max-width: 780px) {
	.wrapper-inner {
		display: flex;
		max-width: 58%;
		overflow: auto;
		padding-bottom: 10px;
	}

}


@media (max-width: 600px) {
	.wrapper-inner {
		display: flex;
		width: 90%;
		overflow: auto;
		padding-bottom: 10px;
	}

}

@media (max-width: 365px) {
	.wrapper-inner {
		display: flex;
		width: 90%;
		overflow: auto;
		padding-bottom: 10px;
	}

}


@media (max-width: 290px) {
	.wrapper-inner {
		display: flex;
		width: 80%;
		overflow: auto;
		padding-bottom: 10px;
	}

}