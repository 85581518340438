
:root{
    --font-family: "Inter", sans-serif;
    --white-color:#ffffff;
    --scorcard-bets-row-border: #D9D9D9;
    --black-color:#333333;

}
/* Styling the match win row -------------- */
.scorcard-match-win-card{
    width: 100%;
    margin-top: 20px;
}
.scorcard-match-win-card-header{
    width: 100%;
    margin: auto;
    border-bottom: 1px solid var(--scorcard-bets-row-border);
}
.scorcard-match-win-card-header-wrapper{
    width: 95%;
    margin: auto;
    padding-bottom: 14px;
}
.scorcard-match-win-header-heading{
    font-family: var(--font-family);
    font-size: 0.9rem;
    font-weight: 650;
    color: var(--black-color);
    padding-bottom: 3px;
    display: flex;
    align-items: center;
   
}
.scorcard-match-win-header-heading img{
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.match-winers-boxes{
    width: 85%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 20px;
}
.winner1{
    width: 45%;
    text-align: center;
}
.winner-name{
    font-family: var(--font-family);
    color: var(--black-color);
    font-size: 1rem;
    font-weight: 650;
}
.winner-score-box{
    width: 80%;
    height: 40px;
    background-color: #0F36AC;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-family);
    color: var(--white-color);
    margin: auto;
    margin-top: 10px;
    font-weight: 650;
}



.winner2{
    width: 45%;
    text-align: center;
}
.winner-name{
    font-family: var(--font-family);
    color: var(--black-color);
    font-size: 0.8rem;
    font-weight: 650;
}
.winner-score-box{
    width: 80%;
    height: 28px;
    background-color: #0F36AC;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-family);
    color: var(--white-color);
    margin: auto;
    margin-top: 10px;
    font-weight: 650;
}