.StatementOuter {
  width: 100%;
}

.statementTop {
  width: 100%;
  background-color: #242424;
  border-bottom: 4px solid #476bc8;
}

.statopshn {
  color: #fff;
  text-align: center;
  margin-bottom: 0;
  padding: 5px 0;
}

.statopshn span {
  font-size: 12px;
  margin: 2px 5px;
}

.searchTp {
  background-color: #242424;
  margin-top: 80px;
}

.clrBoth {
  clear: both;
}

.row-mrs {
  padding: 5px 5px;
  padding-bottom: 10px;
  margin: 0;
  margin-left: 20px;
}

.input-outer .cntrl {
  width: 100%;
  background-color: #fff;
  border: 0;
  padding: 2px 3px;
  border-radius: 8px;
  font-size: 14px;
}

.input-outer {
  margin-top: 3px;
}

.row-mrs .col-4 {
  padding: 0 5px;
}

.searchBTn {
  background: linear-gradient(to right, #436ad8 0%, #b264b9 100%);
  border-radius: 12px;
  padding: 3px 15px;
  margin-top: 25px;
  display: inline-block;
  font-size: 14px;
  text-decoration: none;
  color: #fff;
}

.searchBTn:hover {
  color: #fff;
}

.stmnt {
  border-bottom: 2px solid #a672dd;
  display: inline-block;
  font-size: 14px;
}

.tableOuter {
  padding: 12px 12px;
  background-color: #edebec;
  height: 100%;
  height: 100vh;
}

.frDate {
  font-size: 12px;
  color: #898989;
  margin: 0;
  padding: 0;
}

.tablinner {
  background-color: #fff;
  padding: 5px;
  border: 1px solid #845db0;
  border-radius: 4px;
  margin-bottom: 15px;
}

.stat-mnt {
  border-collapse: collapse;
}

.stat-mnt tr th {
  font-size: 14px;
  font-weight: bold;
  padding: 5px 0px;
  vertical-align: top;
  border: 0px !important;
}

.stat-mnt tr td {
  font-size: 12px;
  font-weight: normal;
  text-align: left;
  padding-left: 7px;
  vertical-align: top;
  border: 0px !important;
}

.lss {
  color: #ae4b4c;
}

.prfts {
  color: #12b928;
}

.master-btn {
  margin-top: 5.5rem !important;
}

.master-table {
  margin-top: 70px !important;
}

.label-reason {
  margin: 0 10px;
}

.label-id {
  margin-bottom: 8px !important;
  margin-left: 5px !important;
}

#text-id {
  padding: 10px !important;
}

/* thead tr th {
  background: linear-gradient(to right, #1f4dd1 0, #cf32dd 100%) !important;
} */


.table.table-view {
  border-collapse: collapse;
  border-spacing: 0;
  /* margin-top: 5px; */
}

.table.table-view > thead tr th {
  background: #C6AB52;
}

table tbody tr td {
  border: 1px solid !important;
}

.bg-table {
  background: none !important;
}
.table-head {
  padding: 4px 0px 2px 10px;
  margin: 0;
}

.table-view tr td {
  border-collapse: collapse;
  border-right: 0;
  padding: 5px;
  font-size: 14px;
  /* border: none; */
  vertical-align: middle;
}

.table-view tr th {
  padding: 5px;
  color: #000;
  font-size: 14px;
  border: 1px solid #000;
}

.table-view tr:nth-child(odd) {
  background-color: #eee;
  /*soft grey*/
}

.table-view tr:nth-child(even) {
  background-color: #fff;
  /*plain old white*/
}

.table-view tr {
  transition: 0.5s;
}

.table-view tr:hover {
  background: #87cefa;
}



.sechBtn {
  color: #fff;
  text-transform: uppercase;
  padding: 5px 5px;
  background-color: #436ad8;
  font-size: 14px;
  /* margin-top: 3px; */
  display: block;
  text-align: center;
  border-radius: 6px;
  height: 35px;
  line-height: 23px;
  /* width: 90px; */
}

.tp-form .form-control {
  padding: 6px 6px;
}

.tp-form .form-control {
  margin-bottom: 10px;
}

.tr-red {
  background-color: #f00 !important;
  color: #fff;
}

.tr-green {
  background-color: #1ba708 !important;
  color: #fff;
}

.tr-orange {
  background-color: #e59c00 !important;
  color: #fff;
}

.search-4 {
  text-align: center;
}

.page-link-prev {
  border: 0;
  width: 60px !important;
}

.page-link-prev:hover {
  background-color: transparent;
}

.page-link:hover {
  background: linear-gradient(to right, #436ad8 0, #b264b9 100%);
  color: #fff;
}

.navbar-vertical .navbar-nav .nav-link.active {
  background: linear-gradient(to right, #436ad8 0, #b264b9 100%);
  color: #fff;
}

.navbar-vertical .navbar-nav .nav-link.page-link-prev.active {
  background: transparent;
}

.page-link.page-link-prev:hover {
  background: transparent;
  color: #8392ab;
  border: 0;
}

.page-link.page-link-prev:focus {
  background: transparent;
  color: #8392ab;
  border: 0;
  box-shadow: none;
}

.page-link.page-link-prev:focus-within {
  background: transparent;
  color: #8392ab;
  border: 0;
  box-shadow: none;
}

.page-item.active .page-link {
  background: linear-gradient(to right, #1f4dd1 0, #cf32dd 100%);
  color: #fff;
}

.checkd {
  color: #1ba708;
}
.crossed {
  color: #f12b2b;
}

.color {
  color:#1ba708
}

.reject {
  color: #f12b2b;
}

@media (min-width: 1200px) {
  .StatementOuter {
    width: 50%;
    margin: 0 380px;
  }

  .popup-content3 {
    background-color: #fff;
    border-radius: 4px;
    max-width: 95%;
    width: 400px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    height: 13.5rem;
    position: fixed;
    top: 50px;
    right: 0;
  }
}

@media screen and (max-width: 290px) {
  .input-outer .cntrl {
    font-size: 14px;
    font-size: 11px;
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 600px) {
  .master-btn {
    margin-top: 1rem !important;
    margin-left: 5px !important;
  }
}